import { inject } from '@angular/core'
import { CanActivateChildFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { UserService } from '~core/services'

export const subscriptionRoutesGuard: CanActivateChildFn = (
  _,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const router = inject(Router)
  const userService = inject(UserService)

  return userService.lastStoredUser().pipe(
    map((user) => {
      if (!user.canAccessSubscriptionRoute(state.url)) {
        return router.createUrlTree(['/user/subscription'])
      }
      return true
    }),
  )
}
